<template>
	<div class="bval-report-executive-summary mdc-layout-grid">
		<div class="mdc-layout-grid__inner">

			<div class="report-section mdc-layout-grid__cell--span-6-desktop">
				<h3 class="report-section__title card__title">Total Derivatives Portfolio</h3>
				<div class="mdc-data-table" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Total Derivatives Portfolio">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell"></th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Current Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Gain/Loss</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(row, key) of results.totalDerivatives" :key="`derivatives_${key}`">
									<td class="mdc-data-table__header-cell">{{ row.label }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.notional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.gainLoss | currency(0,0) }}</td>
								</tr>
							</tbody>

						</table>
					</div>
				</div>

				<div class="mdc-data-table" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Weighted Average Duration">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th></th>
									<th></th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Weighted Average Duration (Dealer Side)</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ results.cnvModDurAverage | number }}</td>
								</tr>
							</tbody>

						</table>
					</div>
				</div>
			</div>

			<div class="report-section mdc-layout-grid__cell--span-6-desktop">
				<h3 class="report-section__title card__title">Portfolio Totals LESS FMV Hedges</h3>
				<div class="mdc-data-table" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Portfolio Totals LESS FMV Hedges">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell"></th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Current Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Gain/Loss</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row">
									<td class="mdc-data-table__header-cell">CSB Legacy</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ portfolioTotalsLessFmv.legacyNotional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ portfolioTotalsLessFmv.legacyLoss | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<td class="mdc-data-table__header-cell">COMBINED</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ portfolioTotalsLessFmv.combinedNotional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ portfolioTotalsLessFmv.combinedLoss | currency(0,0) }}</td>
								</tr>
							</tbody>

						</table>
					</div>
				</div>

				<p>&nbsp;</p>

				<h3 class="report-section__title card__title">Downstream Positions</h3>
				<div class="mdc-data-table" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Portfolio Totals LESS FMV Hedges">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th></th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Market Value</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row">
									<td class="mdc-data-table__header-cell">Positive Mkt Val</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ results.downstreamPositions.positive | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<td class="mdc-data-table__header-cell">Negative Mkt Val</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ results.downstreamPositions.negative | currency(0,0) }}</td>
								</tr>
							</tbody>

						</table>
					</div>

				</div>
			</div>

			<div class="report-section mdc-layout-grid__cell--span-12-desktop">
				<h3 class="report-section__title card__title">List of FMV Hedged Positions</h3>
				<div class="mdc-data-table" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="List of FMV Hedged Positions">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell">Trade ID</th>
									<th class="mdc-data-table__header-cell">Notional Amount</th>
									<th class="mdc-data-table__header-cell">MTM</th>
									<th class="mdc-data-table__header-cell">Counterparty</th>
									<th class="mdc-data-table__header-cell">Offsetting Loan</th>
									<th class="mdc-data-table__header-cell">Loan Ref #</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(row, key) of results.fmvResults" :key="`fmvTrades_${key}`">
									<td class="mdc-data-table__cell">{{ row.swCounterParty }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--number">{{ row.swDealNotlAmt | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--number">{{ row.swMarketVal | currency(0,0) }}</td>
									<td class="mdc-data-table__cell">{{ row.bd }}</td>
									<td class="mdc-data-table__cell">{{ row.borrower }}</td>
									<td class="mdc-data-table__cell">{{ row.tradeId }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">TOTAL</th>
									<th class="mdc-data-table__header-cell mdc-data-table__cell--number">{{ fmvTotals.swDealNotlAmt | currency(0,0) }}</th>
									<th class="mdc-data-table__header-cell mdc-data-table__cell--number">{{ fmvTotals.swMarketVal | currency(0,0) }}</th>
									<td class="mdc-data-table__cell"></td>
									<td class="mdc-data-table__cell"></td>
									<td class="mdc-data-table__cell"></td>
								</tr>
							</tbody>

						</table>
					</div>

				</div>
			</div>

		</div>

		<button type="button" class="button button--primary" @click="download" :disabled="isLoading">
			<span class="mdc-button__ripple"></span>
			<ion-icon name="download" class="mdc-button__icon" aria-hidden="true"></ion-icon>
			<span class="mdc-button__label">Download</span>
		</button>
	</div>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import { MDCDataTable } from '@material/data-table'

import { format } from 'date-fns'
import papaparse from 'papaparse'
import { forceDownload } from '@/lib/utils'

export default {
	name: 'bvalReportBaseSummary',
	components: {
		LoadingLine,
	},
	data: () => ({
		isLoading: false,
		results: {
			downstreamPositions: {},
		},
		tables: [],
		search: {},
	}),
	computed: {
		valuationDate() {
			return this.$store.getters['tools/valuationDate']
		},
		fmvTotals() {
			let totals = {}
			if (!this.results || !this.results.fmvResults)
				return totals

			totals = {
				swDealNotlAmt: this.results.fmvResults.reduce((a, b) => a + Number(b.swDealNotlAmt || 0), 0),
				swMarketVal: this.results.fmvResults.reduce((a, b) => a + Number(b.swMarketVal || 0), 0)
			}

			return totals
		},
		portfolioTotalsLessFmv() {
			let totals = {
				legacyNotional: 0,
				legacyLoss: 0,
				combinedNotional: 0,
				combinedLess: 0,
			}

			if (!this.results || !this.results.totalDerivatives) return totals
			totals.legacyNotional = (Number(this.results.totalDerivatives[0].notional) - Number(this.fmvTotals.swDealNotlAmt))
			totals.legacyLoss = (Number(this.results.totalDerivatives[0].gainLoss) - Number(this.fmvTotals.swMarketVal))
			totals.combinedNotional = (Number(this.results.totalDerivatives[2].notional) - Number(this.fmvTotals.swDealNotlAmt))
			totals.combinedLoss = (Number(this.results.totalDerivatives[2].gainLoss) - Number(this.fmvTotals.swMarketVal))

			return totals
		},
	},
	async mounted() {
		let elements = document.querySelectorAll('.mdc-data-table')
		elements.forEach((el, i )=> {
			this.tables[i] = new MDCDataTable(el)
		})
	},
	methods: {
		async submitForm() {
			const fields = []
			const values = []

			for (const k in this.search) {
				fields.push(k)
				values.push(this.search[k])
			}
			let query = {
				...this.$route.query,
				...{
					fields,
					values,
				}
			}

			this.$router.push({ query, })
		},
		async loadReport(params,) {
			this.isLoading = true

			let results
			try {
				results = await this.$store.dispatch(`tools/generateBvalReport`, { type: 'baseSummary', params, }, )
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}

			let { meta: { valuationDate }, records } = results
			this.search.valuationDate = valuationDate

			return records
		},
		download() {
			const alpha = Array.from(Array(6)).map((e, i) => i + 97)
			const columns = alpha.map((x) => String.fromCharCode(x))

			function _convertColumns(columns, data) {
				let newData = []
				for (const d of data) {
					let newObject = {}
					for (const t in columns) {
						if (d[columns[t]]) {
							newObject[t] = d[columns[t]]
						}
					}
					newData.push(newObject)
				}

				return newData
			}

			const { totalDerivatives, fmvResults, portfolioLessFmv, downstreamPositions, } = this.results
			let totalDerivativesColumns = {
				a: 'label',
				b: 'notional',
				c: 'gainLoss',
			}
			let totalDerivativesRows = [
				...[
					{
						label: 'TOTAL DERIVATIVES PORTFOLIO',
					},
					{
						label: 'Portfolio',
						notional: 'Current Notional',
						gainLoss: 'Gain/Loss'
					}
				],
				...totalDerivatives,
			]
			let totalDerivativesOutput = _convertColumns(totalDerivativesColumns, totalDerivativesRows)


			let fmvColumns = {
				a: 'swCounterParty',
				b: 'swDealNotlAmt',
				c: 'swMarketVal',
				d: 'bd',
				e: 'borrower',
				f: 'tradeId',
			}
			let fmvRows = [
				...[
					{
						swCounterParty: `LIST OF FMV HEDGED POSITIONS`,
					},
					{
						swCounterParty: 'Trade ID',
						swDealNotlAmt: 'Notional Amount',
						swMarketVal: 'MTM',
						bd: 'CounterParty',
						borrower: 'Offsetting Loan',
						tradeId: 'Loan Ref #',
					}
				],
				...fmvResults,
				...[
					{
						swCounterParty: 'TOTAL',
						swDealNotlAmt: fmvResults.reduce((a, b) => a + Number(b.swDealNotlAmt || 0), 0),
						swMarketVal: fmvResults.reduce((a, b) => a + Number(b.swMarketVal || 0), 0),
					},
				],
			]
			let fmvOutput = _convertColumns(fmvColumns, fmvRows)


			let portfolioLessColumns = {
				a: 'label',
				b: 'notional',
				c: 'gainLoss',
			}
			let portfolioLessRows = [
				...[
					{
						label: 'PORTFOLIO TOTALS LESS FMV HEDGES',
					},
					{
						label: 'Portfolio',
						notional: 'Current Notional',
						gainLoss: 'Gain/Loss'
					},
					{
						label: 'CSB Legacy',
						notional: portfolioLessFmv.csbLegacy.notional,
						gainLoss: portfolioLessFmv.csbLegacy.gainLoss,
					},
					{
						label: 'COMBINED',
						notional: portfolioLessFmv.combined.notional,
						gainLoss: portfolioLessFmv.combined.gainLoss,
					}
				],
			]
			let portfolioLessOutput = _convertColumns(portfolioLessColumns, portfolioLessRows)


			let downstreamColumns = {
				a: 'label',
				b: 'value',
			}
			let downstreamRows = [
				{
					label: 'DOWNSTREAM POSITIONS',
				},
				{
					label: 'Positive Mkt Val',
					value: downstreamPositions.positive,
				},
				{
					label: 'Negative Mkt Val',
					value: downstreamPositions.negative,
				},
			]
			let downstreamOutput = _convertColumns(downstreamColumns, downstreamRows)


			const spacer = [{a: '',}]
			let reportRows = [
				...totalDerivativesOutput,
				...spacer,
				...fmvOutput,
				...spacer,
				...portfolioLessOutput,
				...spacer,
				...downstreamOutput,
			]

			let output = papaparse.unparse(reportRows, {
				header: false,
				columns,
			})
			forceDownload(output, `BVAL_Base-Summary_${this.valuationDate}.csv`)
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, ) {
				this.isLoading = true
				const { fields, values, } = to.query

				this.results = await this.loadReport({ fields, values, })
			},
		},
		valuationDate (newVal, oldVal) {
			if (newVal !== this.search.valuationDate) {
				this.search.valuationDate = newVal
				this.submitForm()
			} else {
				this.search.valuationDate = newVal
			}
		},
	},
	metaInfo: () => ({
		title: `Base Summary`,
	}),
}
</script>

<style scoped lang="scss">
</style>
