<template>
	<div class="bval-report-executive-summary mdc-layout-grid">
		<div class="mdc-layout-grid__inner">

			<div class="report-section mdc-layout-grid__cell--span-12-desktop">
				<h4 class="report-section__title">Downstream MTM</h4>
				<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Top 10">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell" style="width: 8%">Rank</th>
									<th class="mdc-data-table__header-cell" style="width: 12%" title="CounterParty">CounterParty</th>
									<th class="mdc-data-table__header-cell" title="Custom ID">Custom ID</th>
									<th class="mdc-data-table__header-cell" title="Associated Bank">Associated Bank</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" title="Current Notional">Current Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" title="Approved VaR">Approved VaR</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" title="MTM">MTM</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" title="AVaR - MTM">AVaR - MTM</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(row, key) of results.top25" :key="`top_${key}`">
									<td class="mdc-data-table__cell">{{ row.rank }}</td>
									<td class="mdc-data-table__cell">{{ row.swCounterParty }}</td>
									<td class="mdc-data-table__cell">{{ row.borrower }}</td>
									<td class="mdc-data-table__cell">{{ row.customer }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swDealNotlAmt | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.approvedVar | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.mtm | currency(0, 0)}}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.varMtm | currency(0, 0)}}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">TOTAL</th>
									<td></td>
									<td></td>
									<td></td>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.top25 || []).reduce((a, b) => a + (Number(b.swDealNotlAmt || 0)), 0) | currency(0, 0) }}</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.top25 || []).reduce((a, b) => a + (Number(b.approvedVar || 0)), 0) | currency(0, 0) }}</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.top25 || []).reduce((a, b) => a + (Number(b.mtm || 0)), 0) | currency(0, 0) }}</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.top25 || []).reduce((a, b) => a + (Number(b.varMtm || 0)), 0) | currency(0, 0) }}</th>
								</tr>
							</tbody>

						</table>
					</div>

				</div>
			</div>

			<div class="report-section mdc-layout-grid__cell--span-12-desktop">
				<h4 class="report-section__title">Bank Summary</h4>
				<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell">Institution</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">DV01</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">PFE</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">gr curr exp</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" style="width: 8%">Net Gross Ratio</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">CEM</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(item, key) in summaryNormal" :key="`summary_${key}`">
									<th class="mdc-data-table__header-cell">{{ item.name }}</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalNotional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalMtm | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalDv01 | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalPfe | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalExposure | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.netGrossRatio }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalCem | currency(0, 0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">TOTAL</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
										{{ (summaryNormal || []).reduce((a, b) => a + (b.totalNotional || 0), 0) | currency(0, 0)}}
									</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
										{{ (summaryNormal || []).reduce((a, b) => a + (b.totalMtm || 0), 0) | currency(0, 0)}}
									</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
										{{ (summaryNormal || []).reduce((a, b) => a + (b.totalDv01 || 0), 0) | currency(0, 0)}}
									</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
										{{ (summaryNormal || []).reduce((a, b) => a + (b.totalPfe || 0), 0) | currency(0, 0)}}
									</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
										{{ (summaryNormal || []).reduce((a, b) => a + (b.totalExposure || 0), 0) | currency(0, 0)}}
									</th>
									<th></th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">
										{{ (summaryNormal || []).reduce((a, b) => a + (b.totalCem || 0), 0) | currency(0, 0)}}
									</th>
								</tr>
								<tr class="mdc-data-table__row" v-for="(item, key) in summarySpecial" :key="`summary_special_${key}`">
									<th class="mdc-data-table__header-cell">{{ item.name }}</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalNotional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalMtm | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalDv01 | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalPfe | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalExposure | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.netGrossRatio }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalCem | currency(0, 0) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="report-section mdc-layout-grid__cell--span-6-desktop">
				<h4 class="report-section__title">Call Report Data</h4>
				<div class="mdc-data-table" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table">
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Positive FV</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).positiveFv | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Negative FV</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).negativeFv | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Total Notional</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).totalNotional | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Swaps where Bank pays fixed</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).bankFixed | currency(0,0) }}</td>
								</tr>
							</tbody>
						</table>

						<p>&nbsp;</p>

						<table class="mdc-data-table__table">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th></th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Net Current Credit Exposure</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">

								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">w/ Bank and Securities Firm</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).creditExposureBank | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">w/ Corporations and Other CPs</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).creditExposureCorp | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">w/ Clearing Houses</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).creditExposureClearing | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">TOTAL</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.callReport || {}).creditExposureClearing + (results.callReport || {}).creditExposureBank +(results.callReport || {}).creditExposureCorp  | currency(0,0) }}</th>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<p>&nbsp;</p>


			</div>

			<div class="report-section mdc-layout-grid__cell--span-6-desktop">
				<h4 class="report-section__title">&nbsp;</h4>
				<div class="mdc-data-table" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="true" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell"></th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">DV01</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(item, key) in results.extras" :key="`extra_${key}`">
									<td class="mdc-data-table__cell">{{ item.name }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalMtm | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalDv01 | currency(0, 0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">TOTAL</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.extras || []).reduce((a, b) => a + (b.totalMtm || 0), 0) | currency(0,0) }}</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.extras || []).reduce((a, b) => a + (b.totalDv01 || 0), 0) | currency(0, 0) }}</th>
								</tr>
							</tbody>
						</table>

						<p>&nbsp;</p>

						<table class="mdc-data-table__table">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th></th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">notional by tenor</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">mkt val by tenor</th>
								</tr>
							</thead>
							<tbody>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Maturity &lt; 1YR</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).maturityLt1Notional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).maturityLt1Exposure | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Maturity 1-5YR</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).maturityLt5Notional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).maturityLt5Exposure | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">Maturity &gt; 5YR</th>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).maturityGt5Notional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ (results.callReport || {}).maturityGt5Exposure | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">TOTAL</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.callReport || {}).maturityGt5Notional + (results.callReport || {}).maturityLt1Notional + (results.callReport || {}).maturityLt5Notional | currency(0,0) }}</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">{{ (results.callReport || {}).maturityGt5Exposure + (results.callReport || {}).maturityLt1Exposure + (results.callReport || {}).maturityLt5Exposure | currency(0,0) }}</th>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</div>
		<button type="button" class="button button--primary" @click="download" :disabled="isLoading">
			<span class="mdc-button__ripple"></span>
			<ion-icon name="download" class="mdc-button__icon" aria-hidden="true"></ion-icon>
			<span class="mdc-button__label">Download</span>
		</button>
	</div>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import { MDCDataTable } from '@material/data-table'

import { format } from 'date-fns'
import papaparse from 'papaparse'
import { forceDownload } from '@/lib/utils'

export default {
	name: 'bvalReportExecutiveSummary',
	components: {
		LoadingLine,
	},
	data: () => ({
		isLoading: false,
		results: {},
		tables: [],
		search: {},
	}),
	computed: {
		valuationDate() {
			return this.$store.getters['tools/valuationDate']
		},
		summaryNormal() {
			let normal = []
			if (this.results.summary) {
				normal = this.results.summary.filter(s => s.type != 'special')
			}
			return normal
		},
		summarySpecial() {
			let special = []
			if (this.results.summary) {
				special = this.results.summary.filter(s => s.type == 'special')
			}
			return special
		},
	},
	async mounted() {
		// this.results = await this.loadReport()
		let elements = document.querySelectorAll('.mdc-data-table')
		elements.forEach((el, i )=> {
			this.$nextTick(() => {
				this.tables[i] = new MDCDataTable(el)
			})
		})
	},
	methods: {
		async submitForm() {
			const fields = []
			const values = []

			for (const k in this.search) {
				fields.push(k)
				values.push(this.search[k])
			}
			let query = {
				...this.$route.query,
				...{
					fields,
					values,
				}
			}

			this.$router.push({ query, })
		},
		async loadReport(params,) {
			this.isLoading = true

			let results
			try {
				results = await this.$store.dispatch(`tools/generateBvalReport`, { type: 'executiveSummary', params, })
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}

			let { meta: { valuationDate }, records } = results
			this.search.valuationDate = valuationDate

			return records
		},
		async download() {
			function _convertColumns(columns, data) {
				let newData = []
				for (const d of data) {
					let newObject = {}
					for (const t in columns) {
						if (d[columns[t]]) {
							newObject[t] = d[columns[t]]
						}
					}
					newData.push(newObject)
				}

				return newData
			}

			const { callReport, extras, summary, top25, } = this.results

			let columns = {
				a: 'A',
				b: 'B',
				c: 'C',
				d: 'D',
				e: 'E',
				f: 'F',
				g: 'G',
				h: 'H',
				i: 'I',
				j: 'J',
			}

			let downstreamColumns = {
				a: 'rank',
				b: 'swCounterParty',
				c: 'borrower',
				d: 'customer',
				e: 'swDealNotlAmt',
				f: 'approvedVar',
				g: 'mtm',
				h: 'varMtm',
				i: '',
			}

			let downstreamRows = [
				...[
					{
						rank: 'DOWNSTREAM MTM',
					},
					{
						rank: 'Rank',
						swCounterParty: 'CounterParty',
						borrower: 'Custom ID',
						customer: 'Associated Bank',
						swDealNotlAmt: 'Current Notional',
						approvedVar: 'Approved VaR',
						mtm: 'MTM',
						varMtm: 'AVaR - MTM',
					}
				],
				...top25,
			]
			let top25output = _convertColumns(downstreamColumns, downstreamRows)

			let summaryColumns = {
				a: 'name',
				b: 'totalNotional',
				c: 'totalMtm',
				d: 'totalDv01',
				e: 'totalPfe',
				f: 'totalExposure',
				g: 'netGrossRatio',
				h: 'totalCem',
			}
			let summaryRows = [
				...[
					{
						name: 'BANK SUMMARY',
					},
					{
						name: 'Institution',
						totalNotional: 'Notional',
						totalMtm: 'MTM',
						totalDv01: 'DV01',
						totalPfe: 'PFE',
						totalExposure: 'gr curr exp',
						netGrossRatio: 'Net Gross Ratio',
						totalCem: 'CEM',
					}
				],
				...summary,
				...extras,

			]
			let summaryOutput = _convertColumns(summaryColumns, summaryRows, )

			let callReportColumns = {
				a: 'label',
				b: 'value1',
				c: 'value2',
			}
			let callReportRows = [
				{
					label: 'CALL REPORT DATA',
				},
				{
					label: 'Positive FV',
					value1: callReport.positiveFv,
				},
				{
					label: 'Negative FV',
					value1: callReport.negativeFv,
				},
				{
					label: 'Total Notional',
					value1: callReport.totalNotional,
				},
				{
					label: 'Swaps where Bank pays fixed',
					value1: callReport.bankFixed,
				},
				{
					label: '',
				},
				{
					label: '',
					value1: 'Net Current Credit Exposure',
				},
				{
					label: 'w/ Bank and Securities Firm',
					value1: callReport.creditExposureBank,
				},
				{
					label: 'w/ Corporations and Other CPs',
					value1: callReport.creditExposureCorp,
				},
				{
					label: 'w/ Clearing Houses',
					value1: callReport.creditExposureClearing,
				},
				{
					label: '',
				},
				{
					label: '',
					value1: 'notional by tenor',
					value2: 'mkt val by tenor',
				},
				{
					label: 'Maturity < 1YR',
					value1: callReport.maturityLt1Notional,
					value2: callReport.maturityLt1Exposure,
				},
				{
					label: 'Maturity 1-5YR',
					value1: callReport.maturityLt5Notional,
					value2: callReport.maturityLt5Exposure,
				},
				{
					label: 'Maturity > 5YR',
					value1: callReport.maturityGt5Notional,
					value2: callReport.maturityGt5Exposure,
				},
			]
			let callReportOutput = _convertColumns(callReportColumns, callReportRows,)


			const spacer = [{a: '',}]
			let reportRows = [
				...top25output,
				...spacer,
				...spacer,
				...summaryOutput,
				...spacer,
				...spacer,
				...callReportOutput,
			]

			let output = papaparse.unparse(reportRows, {
				header: false,
				columns: Object.keys(columns),
			})

			forceDownload(output, `BVAL_Executive-Summary_${this.valuationDate}.csv`)
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, from) {
				this.isLoading = true
				const { fields, values, } = to.query

				this.results = await this.loadReport({ fields, values, })
			},
		},
		valuationDate (newVal, oldVal) {
			if (oldVal && newVal !== this.search.valuationDate) {
				this.search.valuationDate = newVal
				this.submitForm()
			} else {
				this.search.valuationDate = newVal
			}
		},
	},
	metaInfo: () => ({
		title: `Executive Summary`,
	}),
}
</script>

<style scoped lang="scss">
</style>
