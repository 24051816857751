<template>
	<div class="bval-report-daily-qc mdc-layout-grid">
		<div class="mdc-layout-grid__inner">

			<div class="report-section mdc-layout-grid__cell--span-12-desktop bval-table">
				<h3 class="report-section__title card__title">Daily Report</h3>
				<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Top 10">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell">Date</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--notional  mdc-data-table__header-cell--numeric">Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--notional">Notional Chg</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM Chg</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM incl. FMV</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM less FMV Chg</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">DV'01</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">DV'01 Chg</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(row, key) of results.daily" :key="`daily_${key}`" :class="{'mdc-data-table__row--selected': valuationDate == row.date }">
									<td class="mdc-data-table__header-cell">{{ row.date | date }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric mdc-data-table__cell--notional">{{ row.notional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric mdc-data-table__cell--notional">{{ row.notionalChange | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.mtm | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.mtmChange | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.fmvMtm | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.fmvMtmChange | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.dv01 | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.dv01Change | currency(0,0) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="report-section mdc-layout-grid__cell--span-6-desktop">
				<h3 class="report-section__title card__title">Trades Added</h3>
				<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Top 10">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell" style="width: 35%">Trade</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">DV'01</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(row, key) of results.diff.added" :key="`added_${key}`">
									<td class="mdc-data-table__cell" :title="`Maturity: ${row.maturity.value}`">
										<strong>{{ row.borrower }}</strong><br />
										{{ row.swCounterParty }}
									</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swDealNotlAmt | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swMarketVal | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swEqvBpv | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row" v-if="results && results.diff && results.diff.added && results.diff.added.length">
									<td class="mdc-data-table__cell">
										<strong>TOTAL</strong>
									</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric"><strong>{{ (results.diff.added || []).reduce((a, b) => a + Number(b.swDealNotlAmt || 0), 0) | currency(0,0) }}</strong></td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric"><strong>{{ (results.diff.added || []).reduce((a, b) => a + Number(b.swMarketVal || 0), 0) | currency(0,0) }}</strong></td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric"><strong>{{ (results.diff.added || []).reduce((a, b) => a + Number(b.swEqvBpv || 0), 0) | currency(0,0) }}</strong></td>
								</tr>

							</tbody>

						</table>
					</div>

				</div>
			</div>

			<div class="report-section mdc-layout-grid__cell--span-6-desktop">
				<h3 class="report-section__title card__title">Trades Removed</h3>
				<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="true" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Top 10">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell" style="width: 35%">Trade</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">MTM</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">DV'01</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(row, key) of results.diff.removed" :key="`removed_${key}`">
									<td class="mdc-data-table__cell" :title="`Maturity: ${row.maturity.value}`">
										<strong>{{ row.borrower }}</strong><br />
										{{ row.swCounterParty }}
									</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swDealNotlAmt | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swMarketVal | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swEqvBpv | currency(0,0) }}</td>
								</tr>
								<tr class="mdc-data-table__row" v-if="results && results.diff && results.diff.removed && results.diff.removed.length">
									<td class="mdc-data-table__cell">
										<strong>TOTAL</strong>
									</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric"><strong>{{ (results.diff.removed || []).reduce((a, b) => a + Number(b.swDealNotlAmt || 0), 0) | currency(0,0) }}</strong></td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric"><strong>{{ (results.diff.removed || []).reduce((a, b) => a + Number(b.swMarketVal || 0), 0) | currency(0,0) }}</strong></td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric"><strong>{{ (results.diff.removed || []).reduce((a, b) => a + Number(b.swEqvBpv || 0), 0) | currency(0,0) }}</strong></td>
								</tr>
							</tbody>

						</table>
					</div>

				</div>
			</div>
		</div>
		<button type="button" class="button button--primary" @click="download" :disabled="isLoading">
			<span class="mdc-button__ripple"></span>
			<ion-icon name="download" class="mdc-button__icon" aria-hidden="true"></ion-icon>
			<span class="mdc-button__label">Download</span>
		</button>
	</div>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import { MDCDataTable } from '@material/data-table'

import { format } from 'date-fns'
import papaparse from 'papaparse'
import { forceDownload } from '@/lib/utils'

export default {
	name: 'bvalReportDailyQc',
	components: {
		LoadingLine,
	},
	data: () => ({
		isLoading: false,
		results: {
			diff: {
				added: [],
				removed: [],
			},
		},
		tables: [],
		search: {},
	}),
	computed: {
		valuationDate() {
			return this.$store.getters['tools/valuationDate']
		},
	},
	async mounted() {
		// this.results = await this.loadReport()
		let elements = document.querySelectorAll('.mdc-data-table')
		elements.forEach((el, i )=> {
			this.tables[i] = new MDCDataTable(el)
		})

	},
	methods: {
		async submitForm() {
			const fields = []
			const values = []

			for (const k in this.search) {
				fields.push(k)
				values.push(this.search[k])
			}
			let query = {
				...this.$route.query,
				...{
					fields,
					values,
				}
			}

			this.$router.push({ query, })
		},
		async loadReport(params) {
			this.isLoading = true

			let results
			try {
				results = await this.$store.dispatch(`tools/generateBvalReport`, { type: 'dailyQc', params, }, )
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}

			let { meta: { valuationDate }, records } = results
			return records
			return results.data
		},
		download() {
			const alpha = Array.from(Array(19)).map((e, i) => i + 97)
			const columns = alpha.map((x) => String.fromCharCode(x))

			function _convertColumns(columns, data) {
				let newData = []
				for (const d of data) {
					let newObject = {}
					for (const t in columns) {
						if (d[columns[t]]) {
							newObject[t] = d[columns[t]]
						}
					}
					newData.push(newObject)
				}

				return newData
			}

			const { daily, diff, } = this.results
			diff.added.unshift({
				borrower: 'Custom ID',
				swCounterParty: 'CounterParty',
				swDealNotlAmt: 'Notional',
				swMarketVal: 'MTM',
				swEqvBpv: `DV'01`,
			})
			diff.added.unshift({ borrower: `TRADES ADDED (${this.valuationDate})`, })
			diff.removed.unshift({
				borrower: 'Custom ID',
				swCounterParty: 'CounterParty',
				swDealNotlAmt: 'Notional',
				swMarketVal: 'MTM',
				swEqvBpv: `DV'01`,
			})
			diff.removed.unshift({ borrower: `TRADES REMOVED (${this.valuationDate})`,})
			diff.removed.unshift({ borrower: '', })

			let addedRows = Math.min(daily.length, diff.added.length, )
			let removedRows = Math.min(daily.length, diff.removed.length) + addedRows
			let maxRows = Math.max(daily.length, (diff.added.length + diff.removed.length))

			let dailyColumns = {
				a: 'date',
				b: 'notional',
				c: 'notionalChange',
				d: 'mtm',
				e: 'mtmChange',
				f: 'fmvMtm',
				g: 'fmvMtmChange',
				h: 'dv01',
				i: 'dv01Change',
				j: 'skip',
				k: 'borrower',
				l: 'swCounterParty',
				m: 'swDealNotlAmt',
				n: 'swMarketVal',
				o: 'swEqvBpv',
			}

			let diffColumns = {
				k: 'swCounterParty',
				l: 'borrower',
				m: 'swDealNotlAmt',
				n: 'swMarketVal',
				o: 'swEqvBpv',
			}

			const rowData = []
			for (let i = 0; i < addedRows; i++) {
				rowData[i] = {
					...daily[i] || {},
					...diff.added[i] || {},
				}
			}
			for (let i = addedRows; i < removedRows; i++) {
				rowData[i] = {
					...daily[i] || {},
					...diff.removed[i - addedRows] || {},
				}
			}
			for (let i = removedRows; i < maxRows; i++) {
				rowData[i] = {
					...daily[i] || {},
				}
			}

			let dailyRows = [
				...[
					{
						date: 'Date',
						notional: 'Notional',
						notionalChange: 'Notional Chg',
						mtm: 'MTM',
						mtmChange: 'MTM Chg',
						fmvMtm: 'MTM incl. FMV',
						fmvMtmChange: 'MTM less FMV Chg',
						dv01: `DV'01`,
						dv01Change: `DV'01 Change`,
					}
				],
				...rowData,
			]
			let dailyOutput = _convertColumns(dailyColumns, dailyRows)

			const spacer = [{a: '',}]
			let reportRows = [
				...dailyOutput,
				...spacer,
				...spacer,
			]

			let output = papaparse.unparse(reportRows, {
				header: false,
				columns,
			})

			forceDownload(output, `BVAL_Daily-QC_${this.valuationDate}.csv`)
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, ) {
				this.isLoading = true
				const { fields, values, } = to.query

				this.results = await this.loadReport({ fields, values, })
			},
		},
		valuationDate (newVal, oldVal) {
			if (newVal !== this.search.valuationDate) {
				this.search.valuationDate = newVal
				this.submitForm()
			} else {
				this.search.valuationDate = newVal
			}
		},
	},
	metaInfo: () => ({
		title: `Daily QC Report`,
	}),
}
</script>

<style scoped lang="scss">

</style>
