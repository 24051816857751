<template>
	<div class="bval-report-executive-summary mdc-layout-grid">
		<div class="mdc-layout-grid__inner">
			<div class="report-section mdc-layout-grid__cell--span-12-desktop">
				<h4 class="report-section__title">Summary</h4>
				<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="true" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell">Counterparty Name</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Current MTM*</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">DV01</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric">Notional</th>
									<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric mdc-data-table__header-cell--button" style="width:10%"></th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row hover" v-for="(item, key) in results.summary" :key="`data_${key}`">
									<td class="mdc-data-table__header-cell">
										{{ item.label }}
									</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalMtm | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ item.totalDv01 | currency(0, 0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric">
										{{ item.totalNotional | currency(0,0) }}
									</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--numeric mdc-data-table__cell--button">
										<a class="mdc-icon-button hover" title="Remove" @click="remove(key, item)" v-if="!ignoreKeys.includes(item.label)">
											<ion-icon name="close" class="mdc-button__icon" aria-hidden="true"></ion-icon>
										</a>
									</td>
								</tr>
							</tbody>
						</table>
						<p><small>* Current MTM - a negative number indicates that the CP has exposure to SSB, but a positive number indicates that SSB has exposure to the CP, and should demand collateral, if the CSA supports it.</small></p>
					</div>
					<div class="mdc-data-table__progress-indicator mdc-data-table__progress-indicator--bottom">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="true" />
					</div>

				</div>
			</div>

			<form class="form form--trade form-trade mdc-layout-grid__cell mdc-layout-grid__cell--span-6-desktop" @submit.prevent.stop="submitForm(fields)" :novalidate="true">
				<select-field label="Counterparty" v-model="fields.counterParty" :options="counterpartyOptions" :disabled="isLoading" />
				&nbsp;

				<!-- <text-field class="form__field" v-model="fields.counterParty" label="Counterparty" :novalidate="true" /> &nbsp; -->
				<button type="submit" class="form__action button button--unelevated button--secondary" :disabled="isLoading">
					Add
				</button>
			</form>

			<div class="report-section mdc-layout-grid__cell--span-12-desktop">
				<h3 class="report-section__title card__title">Current Exposure to Broker/Dealer Counterparties</h3>
				<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
					<div class="mdc-data-table__progress-indicator">
						<div class="mdc-data-table__scrim"></div>
						<loading-line :loading="isLoading" />
					</div>
					<div class="mdc-data-table__table-container">
						<table class="mdc-data-table__table" aria-label="Current Exposure to Broker/Dealer Counterparties">
							<thead>
								<tr class="mdc-data-table__header-row">
									<th class="mdc-data-table__header-cell">Institution Name</th>
									<th class="mdc-data-table__header-cell">Notional</th>
									<th class="mdc-data-table__header-cell">Exposure</th>
									<th class="mdc-data-table__header-cell">Credit Rating</th>
									<th class="mdc-data-table__header-cell">Dealer Threshold</th>
									<th class="mdc-data-table__header-cell">CSB Threshold</th>
									<th class="mdc-data-table__header-cell">Min Transfer Amt</th>
								</tr>
							</thead>
							<tbody class="mdc-data-table__content">
								<tr class="mdc-data-table__row" v-for="(row, key) of exposureResults" :key="`exposure_${key}`">
									<td class="mdc-data-table__header-cell">{{ row.borrower }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--number">{{ row.notional | currency(0,0) }}</td>
									<td class="mdc-data-table__cell mdc-data-table__cell--number">{{ row.exposure > 0 ? row.exposure : 0 | currency(0,0,true) }}</td>
									<td class="mdc-data-table__cell">{{ row.rating }}</td>
									<td class="mdc-data-table__cell">{{ row.dealer }}</td>
									<td class="mdc-data-table__cell">{{ row.csb }}</td>
									<td class="mdc-data-table__cell">{{ row.transfer }}</td>
								</tr>
								<tr class="mdc-data-table__row">
									<th class="mdc-data-table__header-cell">
										TOTAL
									</th>
									<th class="mdc-data-table__header-cell mdc-data-table__cell--number">{{ exposureResults.reduce((a, b) => a + (Number(b.notional) || 0), 0) | currency(0, 0)}}</th>
									<th class="mdc-data-table__header-cell mdc-data-table__cell--number">{{ exposureResults.reduce((a, b) => a + (Number(b.exposure > 0 ? b.exposure : 0) || 0), 0) | currency(0, 0)}}</th>
									<th class="mdc-data-table__header-cell"></th>
									<th class="mdc-data-table__header-cell"></th>
									<th class="mdc-data-table__header-cell"></th>
									<th class="mdc-data-table__header-cell"></th>
								</tr>
							</tbody>

						</table>
					</div>

				</div>
			</div>
		</div>
		<button type="button" class="button button--primary" @click="download" :disabled="isLoading">
			<span class="mdc-button__ripple"></span>
			<ion-icon name="download" class="mdc-button__icon" aria-hidden="true"></ion-icon>
			<span class="mdc-button__label">Download</span>
		</button>
	</div>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import { MDCDataTable } from '@material/data-table'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'

import papaparse from 'papaparse'
import { forceDownload } from '@/lib/utils'

export default {
	name: 'bvalReportBdCollateral',
	components: {
		LoadingLine,
		SelectField,
		TextField,
	},
	data: () => ({
		isLoading: false,
		results: {},
		tables: [],
		fields: {
			counterParty: '',
		},
		ignoreKeys: [
			'CME',
			'LCH',
		],
		search: {},
	}),
	computed: {
		valuationDate() {
			return this.$store.getters['tools/valuationDate']
		},
		counterpartyOptions() {
			let options = []

			if (!Object.keys(this.results).length) return options
			const counterParties = this.results.summary.filter(r => !this.ignoreKeys.includes(r.label)).map(r => r.label)
			// const bdParties = this.results.bdList.filter(r => !counterParties.includes(r.borrower)).map(r => r.borrower)
			const bdList = ['Bank of America',
				'BofA(2)',
				'BNP Paribas',
				'CitiBank',
				'Comerica Bank',
				'Deutsche Bank',
				'JP Morgan',
				'Mitsubishi UFJ',
				'MUFJ(2)',
				'Morgan Stanley',
				'Raymond James',
				'SunTrust Bank',
				'Synovus Bank',
				'US Bank',
				'Wells Fargo',
				'Goldman Sachs',
				'BMO Harris',
				'PNC Bank',
				'Vining Sparks'
			]
			const bdParties = bdList.filter(r => !counterParties.includes(r))

			options = bdParties.map(b => ({
				label: b,
				value: b,
			}))

			return options
		},
		exposureResults() {
			let stats = {
				'Bank of America': {
					rating: 'Baa1/BBB+',
					dealer: 0,
					csb: 0,
					transfer: `$250M`,
				},
				'BNP Paribas': {
					rating: 'Aa3/A',
					dealer: 0,
					csb: 0,
					transfer: ``,
				},
				'CitiBank': {
					rating: 'Baa1/BBB+',
					dealer: 0,
					csb: 0,
					transfer: `$250M`,
				},
				'Deutsche Bank': {
					rating: 'Baa1/BBB+',
					dealer: `$1MM`,
					csb: 0,
					transfer: ``,
				},
				'JP Morgan': {
					rating: 'A3/A-',
					dealer: 0,
					csb: 0,
					transfer: `$250M`,
				},
				'Mitsubishi UFJ': {
					rating: 'A1/A',
					dealer: 0,
					csb: 0,
					transfer: `$250M`,
				},
				'Morgan Stanley': {
					rating: 'A3/BBB+',
					dealer: 0,
					csb: 0,
					transfer: ``,
				},
				'Raymond James': {
					rating: '',
					dealer: '',
					csb: '',
					transfer: ``,
				},
				'SunTrust Bank': {
					rating: '',
					dealer: '',
					csb: '',
					transfer: ``,
				},
				'Synovus Bank': {
					rating: '',
					dealer: 0,
					csb: 0,
					transfer: '$250k'
				},
				'US Bank': {
					rating: 'A1/A+',
					dealer: 0,
					csb: 0,
					transfer: `$250M`,
				},
				'Wells Fargo': {
					rating: 'A2/A',
					dealer: '$10MM',
					csb: 0,
					transfer: `$250M`,
				},
				'Goldman Sachs': {
					rating: 'A3/BBB+',
					dealer: '',
					csb: '',
					transfer: ``,
				},
				'Summit Bank': {
					rating: '',
					dealer: '',
					csb: '',
					transfer: ``,
				},
				'West Alabama Bank and Trust': {
					rating: '',
					dealer: '',
					csb: '',
					transfer: ``,
				},
			}

			let combinedResults = []
			if (!this.results || !this.results.exposureResults) return combinedResults

			return this.results.exposureResults.map(r => {
				if (stats[r.borrower]) {
					r = {...r, ...stats[r.borrower]}
				}

				return r
			})
		},
	},
	async mounted() {
		// this.results = await this.loadReport()
		let elements = document.querySelectorAll('.mdc-data-table')
		elements.forEach((el, i )=> {
			this.tables[i] = new MDCDataTable(el)
		})
	},
	methods: {
		async submitSearch() {
			const fields = []
			const values = []

			for (const k in this.search) {
				fields.push(k)
				values.push(this.search[k])
			}
			let query = {
				...this.$route.query,
				...{
					fields,
					values,
				}
			}

			this.$router.push({ query, })
		},
		async loadReport(params) {
			this.isLoading = true

			let results
			try {
				results = await this.$store.dispatch(`tools/generateBvalReport`, { type: 'bdCollateral', params, }, )
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}

			let { meta: { valuationDate }, records } = results
			this.search.valuationDate = valuationDate

			return records
		},
		download() {
			const alpha = Array.from(Array(7)).map((e, i) => i + 97)
			const columns = alpha.map((x) => String.fromCharCode(x))

			function _convertColumns(columns, data) {
				let newData = []
				for (const d of data) {
					let newObject = {}
					for (const t in columns) {
						if (d[columns[t]]) {
							newObject[t] = d[columns[t]]
						}
					}
					newData.push(newObject)
				}

				return newData
			}

			const { summary, } = this.results

			let summaryColumns = {
				a: 'label',
				b: 'totalMtm',
				c: 'totalDv01',
				d: 'totalNotional',
			}
			let summaryRows = [
				...[
					{
						label: 'CounterParty Name',
						totalMtm: 'Current MTM*',
						totalDv01: `DV'01`,
						totalNotional: 'Notional',
					}
				],
				...summary,
				...[
					{
						label: `* Current MTM - a negative number indicates that the CP has exposure to SSB, but a positive number indicates that SSB has exposure to the CP, and should demand collateral, if the CSA supports it.`
					}
				]
			]
			let summaryOutput = _convertColumns(summaryColumns, summaryRows)

			let exposureColumns = {
				a: 'borrower',
				b: 'notional',
				c: 'exposure',
				d: 'rating',
				e: 'dealer',
				f: 'csb',
				g: 'transfer',
			}
			let exposureRows = [
				...[
					{
						borrower: 'CURRENT EXPOSURE TO BROKER DEALER COUNTERPARTIES',
					},
					{
						borrower: 'Institution Name',
						notional: 'Notional',
						exposure: 'Exposure',
						rating: 'Credit Rating',
						threshold: 'Dealer Threshold',
						csb: 'CSB Threshold',
						transfer: 'Min Transfer Amt',
					},
				],
				...this.exposureResults,
			]
			let exposureOutput = _convertColumns(exposureColumns, exposureRows)

			const spacer = [{a: '',}]
			let reportRows = [
				...summaryOutput,
				...spacer,
				...exposureOutput,
			]

			let output = papaparse.unparse(reportRows, {
				header: false,
				columns,
			})
			forceDownload(output, `BVAL_BD-Collateral_${this.valuationDate}.csv`)
		},
		submitForm(fields) {
			const counterParties = this.results.summary.filter(r => !this.ignoreKeys.includes(r.label)).map(r => r.label)
			counterParties.push(fields.counterParty)

			this.isLoading = true
			this.$store.dispatch('tools/updateBvalBdCollateral', { counterParties })
				.then(async (res) => {
					this.results = await this.loadReport()
					this.fields = {}
					this.$notice('Added!')
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		remove(idx, item) {
			this.$confirm(`This will remove "${item.label}" from the BD Collateral Requirements.`, () => {
				this.results.summary.splice(idx, 1)
				const counterParties = this.results.summary.filter(r => !this.ignoreKeys.includes(r.label)).map(r => r.label)

				this.isLoading = true
				this.$store.dispatch('tools/updateBvalBdCollateral', { counterParties })
					.then(async (res) => {
						this.$notice('Removed!')
						this.results = await this.loadReport()
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			}, {
				title: `Remove from BD Collateral?`,
			})
		},
	},
	watch: {
		valuationDate (newVal, oldVal) {
			if (oldVal && newVal !== this.search.valuationDate) {
				this.search.valuationDate = newVal
				this.submitSearch()
			} else {
				this.search.valuationDate = newVal
			}
		},
		$route: {
			immediate: true,
			handler: async function (to, from) {
				this.isLoading = true
				const { fields, values, } = to.query

				this.results = await this.loadReport({ fields, values, })
			},
		},
	},
	metaInfo: () => ({
		title: `BD Collateral Requirements`,
	}),
}
</script>

<style scoped lang="scss">
@use "@material/icon-button";

@include icon-button.core-styles;

.mdc-data-table {
	&__header {
		&-cell {
			&--button {
				width: 80px;
			}
		}
	}

	&__cell {
		&--button {
			width: 80px;
		}
	}
}
</style>
