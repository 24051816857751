<template>
	<div class="partial partial--bval bval">
		<div class="partial__heading">
			<h2 class="partial__title">BVAL</h2>
		</div>

		<!-- <div class="message">
			We're updating the link to BVAL and this tab access will be removed on 7/15/23.  Please utilize the link below or the BVAL tile on the resource homepage to access BVAL going forward. <a :href="portalUrl">Please visit here and update any bookmarks you may have saved.</a>
		</div> -->

		<div class="partial__tabs">
			<tab-bar
				class="partial__tab-bar"
				:items="tabs"
				>
				<template slot-scope="{ item, idx }" slot="item">
					<router-link
						class="tab-bar__item a"
						:class="{'tab-bar__item--active': item.link.name == activeTab.link.name}"
						:id="`bval_nav_${idx}`"
						:to="item.link"
						>
						{{ item.label }}
					</router-link>
				</template>
			</tab-bar>
			<div class="partial__tabs-meta">
				<select-field label="Valuation Date" v-model="fields.valuationDate" class="mdc-select--basic" :options="valuationDates" @input="setValuationDate" />
			</div>
		</div>

		<card class="partial__body">
			<router-view name="bval" />
		</card>
	</div>
</template>

<script>
import Card from '@/components/Card'
import Modal from '@/components/Modal'
import SelectField from '@/components/SelectField'
import TabBar from '@/components/TabBar'
import qs from 'qs'

export default {
	name: 'partialBval',
	components: {
		Card,
		Modal,
		SelectField,
		TabBar,
	},
	data: () => ({
		activeTab: {},
		isLoading: false,
		fields: {},
	}),
	computed: {
		user() {
			return this.$store.getters['user/me']
		},
		portalUrl() {
			return (process.env.PORTAL_URL || process.env.VUE_APP_PORTAL_URL).replace('services','bval') + `?${qs.stringify({ token: this.user.token })}`
		},
		valuationDate() {
			return this.$store.getters['tools/valuationDate']
		},
		hasBvalSsb() {
			return this.$store.getters['user/hasBvalSsb']
		},
		tab() {
			return this.$route.params.tab
		},
		tabs() {
			let query = {
				fields: ['valuationDate'],
				values: [this.valuationDate],
			}

			let links = [
				{
					label: 'All Positions',
					link: { name: `bval`, query,  },
				},
			]

			if (!this.hasBvalSsb) {
				links = [...links, ...[
				{
					label: 'Executive Summary',
					link: { name: 'bvalExecutiveSummary', query, },
				},
				{
					label: 'Daily QC Report',
					link: { name: 'bvalDailyQc', query, },
				},
				{
					label: `Base Summary`,
					link: { name: 'bvalBaseSummary', query, },
				},
				{
					label: `BD Collateral Requirements`,
					link: { name: 'bvalBdCollateral', query, },
				},
				]]
			}

			return links

			return [
				{
					label: 'All Positions',
					link: { name: `bval`, query,  },
				},
				{
					label: 'Executive Summary',
					link: { name: 'bvalExecutiveSummary', query, },
				},
				{
					label: 'Daily QC Report',
					link: { name: 'bvalDailyQc', query, },
				},
				{
					label: `Base Summary`,
					link: { name: 'bvalBaseSummary', query, },
				},
				{
					label: `BD Collateral Requirements`,
					link: { name: 'bvalBdCollateral', query, },
				},
			]
		},
		modalVisible() {
			return this.$route.name == 'tradeEdit'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		bvalData() {
			return this.$store.getters['tools/bvalData']
		},
		meta() {
			return this.bvalData.meta || {}
		},
		valuationDates() {
			if (this.bvalData.dates) {
				return this.bvalData.dates
			}

			return []
		},
	},
	mounted() {
		this.$store.dispatch('tools/queryBvalDates')
	},
	methods: {
		hideModal(clicked) {
			if (clicked) {
				this.$router.replace({ name: 'tools', params: { tab: this.$route.params.tab }})
			} else {
				this.$router.back()
			}
		},
		setValuationDate() {
			this.$store.dispatch('tools/setBvalValuation', this.fields.valuationDate)
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: function (newVal) {
				const activeTab = this.tabs.find(t => t.link.name == newVal.name)
				if (activeTab) {
					this.activeTab = activeTab
				}
			},
		},
		valuationDate: {
			immediate: true,
			handler: function (newVal) {
				if (newVal) {
					this.$set(this.fields, 'valuationDate', newVal)
				}
			},
		},
	},
	metaInfo: () => ({
		title: `BVAL Trades`,
	}),
}
</script>

<style scoped lang="scss">
::v-deep .report-section {
	@include modules.gutter('margin-bottom');

	&__title {
		@include modules.fontSize(16px);
		font-weight: 500;
		line-height: 1;
		margin-left: 0;
		// margin: 0;
	}
}

.partial {
	&__tabs {
		&-meta {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
		}
	}
}

.message {
	background-color: modules.color_('warn','background');
	border: 1px solid modules.color_('warn','warn');
	border-radius: 3px;
	font-size: 14px;
	margin-bottom: 1rem;
	padding: 0.5rem;
	text-align: center;
}
</style>
