<template>
	<form class="form bval-trade-log" @submit.prevent.stop="submitForm">

		<div class="form-section form-tools__totals" :class="{'form-tools__totals--loading': isLoading }">
			<dl class="form-tools__total">
				<dt>Total Notional</dt>
				<dd>{{ totals.swDealNotlAmt_sum || 0 | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Daily Notional Change</dt>
				<dd>{{ totals.notionalNChange | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total Market Value</dt>
				<dd>{{ totals.swMarketVal_sum || 0 | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total DV01</dt>
				<dd>{{ totals.dv01value_sum || totals.o1value_sum || 0 | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total Notional (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.swDealNotlAmt_sum) + Number(totalsB.swDealNotlAmt_sum)) || 0 | currency">{{ totalsB.swDealNotlAmt_sum || 0 | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Daily Notional Change (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.notionalNChange) + Number(totals.notionalBChange)) || 0 | currency">{{ totals.notionalBChange | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total Market Value (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.swMarketVal_sum) + Number(totalsB.swMarketVal_sum)) || 0 | currency">{{ totalsB.swMarketVal_sum || 0 | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__total">
				<dt>Total DV01 (B)</dt>
				<dd class="tooltip" :data-text="(Number(totals.dv01value_sum || totals.o1value_sum) + Number(totalsB.dv01value_sum || totalsB.o1value_sum)) || 0 | currency">{{ totalsB.dv01value_sum || totalsB.o1value_sum || 0 | currency(0, 0) }}</dd>
			</dl>
			<dl class="form-tools__meta">
				<dt>Valuation Date</dt>
				<dd v-if="valuationDate">
					{{ valuationDate | date('MM/dd/yy')}}
				</dd>
			</dl>

		</div>

		<div class="form-section form-section--search">
			<div class="form-section--bval-controls">
				<a class="button" @click="isSearching = !isSearching">
					<ion-icon :name="(isSearching || hasSearch) ? 'close' : 'search'" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Search</span>
				</a>
				<!-- <bval-reports-menu  v-show="!isSearching" @generateReport="generateReport" /> -->
			</div>
			<div class="form-row form-row--col-4 search" :class="{'search--open':(isSearching || hasSearch)}">
				<text-field class="form__field" v-model="search.borrower" label="Custom ID" />
				<text-field class="form__field" v-model="search.customer" label="Bank" />
				<text-field class="form__field" v-model="search.swCounterParty" label="CounterParty" />
				<text-field class="form__field" v-model="search.swPayCpn" label="Pay Coupon" />
				<text-field class="form__field" v-model="search.payFltRateIdx" label="Flt Index" />
				<text-field class="form__field" v-model="search.swPaySpread" label="Flt Spread" />
				<text-field class="form__field" v-model="search.swEffDt" label="Effective Date" placeholder="mm/dd/yyyy" />
				<div class="form__actions">
					<button type="submit" class="button button--primary button--unelevated form__action" :disabled="isLoading">Search</button>
					<button type="cancel" class="button button--secondary" @click="clearQuery()">clear</button>
				</div>
			</div>
		</div>

		<tab-bar
			:items="legacyTypes"
			class="form-tools__tabs">
			<template slot-scope="{ item, idx }" slot="item">
				<div
					class="tab-bar__item a"
					:class="{'tab-bar__item--active': search.legacyType == item.link}"
					:id="`type_tab_${idx}`"
					@click="search.legacyType = item.link; submitForm()"
					>
					{{ item.label }}
				</div>
			</template>
		</tab-bar>

		<div class="mdc-data-table mdc-data-table--sticky-header" :class="{'mdc-data-table--in-progress':isLoading}">
			<div class="mdc-data-table__progress-indicator">
				<div class="mdc-data-table__scrim"></div>
				<loading-line :loading="true" />
			</div>
			<div class="mdc-data-table__table-container">
				<table class="mdc-data-table__table" aria-label="BVAL">
					<thead>
						<tr class="mdc-data-table__header-row">
							<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Custom ID</th>
							<th class="mdc-data-table__header-cell" role="columnheader" scope="col">Bank</th>
							<th class="mdc-data-table__header-cell" role="columnheader" scope="col">CounterParty</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Current Not.</th>
							<!-- <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Next Not.</th> -->
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Fixed</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Spread</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">Start</th>
							<th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">End</th>
						</tr>
					</thead>
					<tbody class="mdc-data-table__content">
					<template v-for="(row, key) of trades">
						<tr class="mdc-data-table__row" :key="key" @click.prevent="toggleRow(key)" :class="{'mdc-data-table__row--selected':isToggled.includes(key), 'row--is-b': isB(row)}">
							<th class="mdc-data-table__cell mdc-data-table__cell--name" scope="row" :title="`#${key+1}`">{{ row.borrower }}</th>
							<td class="mdc-data-table__cell mdc-data-table__cell--name">{{ row.customer }}</td>
							<td class="mdc-data-table__cell">{{ row.swCounterParty }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swDealNotlAmt | currency(0, 0) }}</td>
							<!-- <td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.notional | currency }}</td> -->
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swRecCpn | percentage }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swPaySpread }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.swEffDt | date }}</td>
							<td class="mdc-data-table__cell mdc-data-table__cell--numeric">{{ row.maturity | date }}</td>
						</tr>
						<tr class="mdc-data-table__row mdc-data-table__row--details" :key="`toggle_${key}`">
							<td class="mdc-data-table__cell mdc-data-table__cell--details" colspan="8">
								<dl class="bval-trade__details">

									<dt>Swap ID</dt>
									<dd>{{ row.swapId }}</dd>

									<dt>Swap Type</dt>
									<dd>{{ row.swType }}</dd>

									<dt>Next Pay Date</dt>
									<dd>{{ row.swPayNxtCpnDt | date }}</dd>

									<dt>Market Coupon</dt>
									<dd>{{ row.swParCpn * 100 | number(false, 0) }}</dd>

									<dt>Current Exposure</dt>
									<dd>{{ row.swMarketVal > 0 ? row.swMarketVal : 0 | currency }}</dd>

									<dt>Counter Party</dt>
									<dd>{{ row.swCounterParty }}</dd>

									<dt>Leverage</dt>
									<dd>{{ row.swPayLeverage * 100 }}</dd>

									<dt>VaR (Trade Log)</dt>
									<dd>{{ row.var | currency }}</dd>

									<dt>Effective Date</dt>
									<dd>{{ row.swEffDt | date }}</dd>

									<dt>BB DV01</dt>
									<dd>{{ (row.dv01 || row.swEqvBpv) | currency }}</dd>

									<dt>Associated Bank</dt>
									<dd>{{ row.customer }}</dd>

									<dt>Current Exp (App VaR)</dt>
									<dd>{{ row.varToValue | percentage }}</dd>

									<dt>Maturity</dt>
									<dd>{{ row.maturity | date }}</dd>

									<dt>Net Accr Int</dt>
									<dd>{{ row.swNetAccInt | currency }}</dd>

									<dt>Derivitives</dt>
									<dd>{{ row.swCounterParty }}</dd>

									<dt>Credit Exp (Dodd Frank)</dt>
									<dd>{{ (row.creditExpDodd || 0) | currency }}</dd>

									<dt>Currency</dt>
									<dd>{{ row.crncy }}</dd>

									<dt>Pay Freq</dt>
									<dd>{{ row.swPayFreq }}</dd>

									<dt>Fair Value</dt>
									<dd>{{ row.swMarketVal | currency }}</dd>

									<dt>Exposure to Corps</dt>
									<dd>{{ row.exposureToCorps | currency }}</dd>

									<dt>Pay Coupon</dt>
									<dd>{{ row.swPayCpn * 100 | number(false, 3)}}</dd>

									<dt>Flt Index</dt>
									<dd>{{ row.payFltRateIdx }}</dd>

									<dt>Clearing</dt>
									<dd>{{ row.clearing }}</dd>

									<dt>Exposure Rank 1</dt>
									<dd>{{ row.rank1 }}</dd>

									<dt>Pay Type</dt>
									<dd>{{ row.swPayCpnType }}</dd>

									<dt>Flt Spread</dt>
									<dd>{{ row.swPaySpread }}</dd>

									<dt>BD Trade</dt>
									<dd>{{ row.bd }}</dd>

									<dt>Exposure Rank 2</dt>
									<dd>{{ row.rank2 }}</dd>

									<dt>Receive Coupon</dt>
									<dd>{{ row.swRecCpn * 100 | number(false, 3) }}</dd>

									<dt>Market Value</dt>
									<dd>{{ row.swMarketVal | currency }}</dd>

									<dt>BD Trade ID</dt>
									<dd>{{ row.bdTradeId }}</dd>

									<dt>Downstream Positive Mkt Val</dt>
									<dd>{{ row.downstreamPositive | currency }}</dd>

									<dt>Receive Type</dt>
									<dd>{{ row.swRecCpnType }}</dd>

									<dt>Bank 2</dt>
									<dd>{{ row.bank2 }}</dd>

									<dt>Downstream Negative Mkt Val</dt>
									<dd>{{ row.downstreamNegative | currency }}</dd>

									<dt>Current Notional</dt>
									<dd>{{ row.swDealNotlAmt | currency }}</dd>
									<!-- <dt>Next Notional</dt>
									<dd>{{ row.notional | currency }}</dd> -->

									<dt>Day Count Pay</dt>
									<dd>{{ row.swPayDayCnt }}</dd>

									<dt>CME / LCH</dt>
									<dd>{{ row.clearingHouse }}</dd>

									<dt>Years to Maturity</dt>
									<dd>{{ row.yearFrac | number(false, 4) }}</dd>

									<dt>Day Count Rec</dt>
									<dd>{{ row.swRecDayCnt }}</dd>

									<dt>Market Wire ID</dt>
									<dd>{{ row.marketWireId }}</dd>

									<dt>Clearing House ID</dt>
									<dd>{{ row.clearingHouseId }}</dd>

									<dt>CounterParty ID</dt>
									<dd>{{ row.counterPartyId }}</dd>

									<dt>Portfolio</dt>
									<dd>{{ row.legacyType | enums('LegacyType') }}</dd>
								</dl>

								<div class="mdc-data-table__edit" v-if="!hasBvalSsb">
									<router-link :to="{name: 'tradeEdit', params: { tab: $route.params.tab, counterparty: row.swCounterParty, tradeId: row.tradeId, }, query: $route.query}" class="button button--primary" @click.native="$event.stopImmediatePropagation()" :title="`Edit ${row.borrower} - ${row.swCounterParty}`">Edit</router-link>
								</div>
							</td>
						</tr>
					</template>
					</tbody>
				</table>
			</div>
			<div class="mdc-data-table__progress-indicator mdc-data-table__progress-indicator--bottom">
				<div class="mdc-data-table__scrim"></div>
				<loading-line :loading="true" />
			</div>

			<div class="mdc-data-table__pagination">
				<select-field v-model="params.limit" label="Per Page" :options="limitOptions" @input="changeLimit" />
				<pagination :limit="params.limit" :count="count" />
			</div>
		</div>

		<div class="form-section">
			<div class="form-tools__details">
				<dl class="form-tools__meta">
					<dt>Valuation Date</dt>
					<dd v-if="meta && meta.valuationDate">
						{{ meta.valuationDate | date('MM/dd/yy')}}
					</dd>
				</dl>
				<dl class="form-tools__meta">
					<dt>Date Imported</dt>
					<dd v-if="meta && meta.modifiedDateTime">
						{{ meta.modifiedDateTime | date('MM/dd/yy hh:mma')}} {{ timezone }}
					</dd>
				</dl>
				<dl class="form-tools__meta">
					<dt>Total Records</dt>
					<dd v-if="meta && meta.rowCount">
						{{ meta.rowCount | number(false, 0) }}
					</dd>
				</dl>
			</div>
		</div>

		<div class="form-section">
			<div class="form__actions form__actions--horizontal">
				<button type="button" class="button button--primary" @click="download" :disabled="isLoading">
					<span class="mdc-button__ripple"></span>
					<ion-icon name="download" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Download</span>
				</button>
				<button type="button" class="button button--primary form__action" @click="downloadCusip" :disabled="isLoading">
					<span class="mdc-button__ripple"></span>
					<ion-icon name="archive" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Export CUSIP</span>
				</button>
				<button class="button button--primary form__action" type="button" @click="importData()" :disabled="isLoading" v-if="isDev">(dev) Import BVAL Data</button>
			</div>
		</div>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="partial__modal" slot="content" name="bvalModal" @close="hideModal(true)" />
		</modal>
	</form>
</template>

<script>
import LoadingLine from '@/components/LoadingLine'
import LoadingSpinner from '@/components/LoadingSpinner'
import Pagination from '@/components/Pagination'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import BvalReportsMenu from '@/components/BvalReportsMenu'
import { MDCDataTable } from '@material/data-table'
import papaparse from 'papaparse'
import { format } from 'date-fns'
import { forceDownload } from '@/lib/utils'
import { LegacyType } from '@/lib/enums'
import { Workbook } from 'exceljs'

import Modal from '@/components/Modal'
import TabBar from '@/components/TabBar'

export default {
	name: 'BvalTradeLog',
	components: {
		LoadingLine,
		LoadingSpinner,
		Pagination,
		SelectField,
		Modal,
		TabBar,
		TextField,
		BvalReportsMenu
	},
	data: () => ({
		isLoading: false,
		table: undefined,
		results: {
			updated: [],
			errors: [],
		},
		isToggled: [],
		params: {
			limit: 100,
		},
		limitOptions: [
			{
				value: 100,
				label: 100,
			},
			{
				value: 250,
				label: 250,
			},
			{
				value: 500,
				label: 500,
			},
			{
				value: 1000,
				label: 1000,
			},
		],
		isSearching: false,
		timezone: new Date().toLocaleTimeString('en-us', {timeZoneName: 'short'}).split(' ')[2],
	}),
	computed: {
		bvalData() {
			return this.$store.getters['tools/bvalData']
		},
		hasBvalSsb() {
			return this.$store.getters['user/hasBvalSsb']
		},
		trades() {
			return this.bvalData.trades
		},
		totals() {
			return this.$store.getters['tools/totals']
		},
		totalsB() {
			return this.$store.getters['tools/totalsB']
		},
		meta() {
			return this.bvalData.meta
		},
		valuationDate() {
			return this.$store.getters['tools/valuationDate']
		},
		count() {
			return this.trades.length
		},
		modalVisible() {
			return this.$route.name == 'tradeEdit'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		search() {
			let s = {}
			const { fields, values } = this.$route.query
			if (fields && fields.length) {
				fields.forEach((field, i) => {
					s[field] = values[i]
				})
			}
			return s
		},
		hasSearch() {
			const { fields = [], values = [], } = this.$route.query
			const _searchFields = ['borrower', 'customer', 'swCounterParty', 'swPayCpn', 'payFltRateIdx', 'swPaySpread', 'swEffDt']
			let has = false

			for (let i = 0; i < (fields || []).length; i++) {
				if (_searchFields.includes(fields[i]) && values[i]) {
					has = true
				}
			}

			return has
		},
		legacyTypes() {
			let types = [
				{
					label: `All Trades`,
					link: null,
				},
			]

			if (!this.hasBvalSsb) {
				types.push({
					label: `ARC Trades`,
					link: `none`,
				},)
				for (const t in LegacyType) {
					let suffix = ` Trades`
					types.push({
						label: `${LegacyType[t]}${suffix}`,
						link: t,
					})
				}
			}

			// for (const t in LegacyType) {
			// 	let suffix = ` Trades`
			// 	// let suffix = t == 'fmv' ? `` : ` Trades`
			// 	types.push({
			// 		label: `${LegacyType[t]}${suffix}`,
			// 		link: t,
			// 	})
			// }

			return types
		},
		isDev() {
			return process.env.NODE_ENV == 'development'
		},
	},
	mounted() {
		this.table = new MDCDataTable(document.querySelector('.mdc-data-table'))
	},
	methods: {
		async importData() {
			this.isLoading = true
			try {
				let results = await this.$store.dispatch(`tools/importBvalData`)
				this.$notice(`BVAL data imported from ${results}.`, null, {
					timeoutMs: -1,
				})
				this.queryData()
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}
		},
		async queryData() {
			this.isLoading = true
			const { p, limit } = this.$route.query
			try {
				await this.$store.dispatch(`tools/queryBvalData`, { p, limit })
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}
		},
		async download() {

			let filename = `BVAL_Trade-Log_${this.valuationDate}`

			this.isLoading = true
			const { p, limit, fields, values, orderBy, order, } = this.$route.query

			try {
				const results = await this.$store.dispatch(`tools/downloadBvalData`, { p: 1, limit: 12000, fields, values, orderBy, order, download: filename, })

				const { trades } = results

				const columns = {
					borrower: 'Custom ID',
					swapId: 'Swap ID',
					securities: 'Securities',
					swType: 'Swap Type',
					swCounterParty: 'Counterparty',
					swEffDt: 'Effective Date',
					maturity: 'Maturity',
					swPayCpn: 'Pay Coupon',
					swPayCpnType: 'Pay Type',
					swRecCpn: 'Receive Coupon',
					swRecCpnType: 'Receive Type',
					swDealNotlAmt: 'Notional',
					swPayNxtCpnDt: 'Next Pay Date',
					swEqvBpv: 'DV01',
					swNetAccInt: 'Net Accr Int',
					swPayFreq: 'Pay Freq',
					payFltRateIdx: 'Flt Index',
					swPaySpread: 'Pay Spread',
					swRecSpread: 'Rec Spread',
					swMarketVal: 'Market Value',
					swPayDayCnt: 'Day Count Pay',
					swRecDayCnt: 'Day Count Rec',
					swParCpn: 'Current Market Coupon',
					swPayLeverage: 'Leverage Pay',
					swRecLeverage: 'Leverage Rec',
					customer: 'Associated Bank',
					clearing: 'Clearing 1',
					bank2: 'Bank 2',
					clearingHouse: 'CME / LCH',
					yearFrac: 'Years to Maturity',
					creditExpDodd: 'Credit Exposure per Dodd Frank',
					exposureToCorps: 'Exposure to Corps',
					rank1: 'Exposure Rank - Step 1',
					rank2: 'Exposure Rank - Step 2',
					downstreamPositive: 'Downstream Positive',
					downstreamNegative: 'Downstream Negative',
					swCnvModDur: 'Conv Mod Dur',
					legacyType: 'Portfolio',
				}
				const _valueColumns = ['swEffDt', 'maturity', 'swPayNxtCpnDt', ]

				const filtered = trades.map(r => {
					let _r = {}
					r.swEqvBpv = r.dv01 || r.swEqvBpv
					Object.keys(columns).forEach(c => {
						if (c == 'legacyType') {
							r[c] = this.$options.filters.enums(r[c], 'LegacyType')
						}
						_r[c] = _valueColumns.includes(c) && r[c] ? r[c].value : r[c]
					})
					return _r
				})
				filtered.unshift(columns)

				const unparsed = papaparse.unparse(filtered, {
					header: false,
				})
				forceDownload(unparsed, `${filename}.csv`)
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}
		},
		async downloadCusip() {
			let filename = `BVAL_CUSIP_Download_${this.valuationDate}`

			this.isLoading = true
			const { p, limit, fields, values, orderBy, order, } =  this.$route.query

			try {
				const results = await this.$store.dispatch(`tools/downloadBvalData`, { p: 1, limit: 12000, fields, values, orderBy, order, download: filename, })

				const { trades } = results

				const workbook = new Workbook()
				const _sheetOptions = {
					properties: {
					}
				}
				const xsaSheet = workbook.addWorksheet(`XSA Download`, _sheetOptions)
				const ssbSheet = workbook.addWorksheet(`SSB Download`, _sheetOptions)
				const fmvSheet = workbook.addWorksheet(`FMV Download`, _sheetOptions)
				const acbSheet = workbook.addWorksheet(`ACB Download`, _sheetOptions)

				const sortTrades = trades
					.map(t => {
						t.swCounterParty = t.swCounterParty.replace(/SAN MARCO/i, '2019_0829_6')
						return t
					})
					.map(t => {
						const cpSplit = t.swCounterParty.match(/(\d{4})_(\d{4})_?(\d+)?([A-Z])?/)
						if (!cpSplit) {
							t.tradeKey = t.swCounterParty
							return t
						}
						let dayKey = cpSplit[3] ? cpSplit[3].padStart(2, '0') : '00'
						let bKey = cpSplit[4] ? cpSplit[4] : 'A'
						if (cpSplit[4]) {
							t.var = 'N/A'
						}
						t.tradeKey = [cpSplit[1], cpSplit[2], dayKey, bKey].join('')
						return t
					})
					.sort((a, b) => a.tradeKey > b.tradeKey ? 1 : -1)

				const xsaTrades = sortTrades.filter(t => !t.legacyType)
				const ssbTrades = sortTrades.filter(t => t.legacyType?.match(/ssb/i))
				const fmvTrades = sortTrades.filter(t => t.legacyType?.match(/fmv/i))
				const acbTrades = sortTrades.filter(t => t.legacyType?.match(/atl/i))

				const xsaColumns = [
					{
						header: `Associated Bank`,
						key: 'customer',
						width: 40,
					},
					{
						header: `CME/LCH`,
						key: `clearingHouse`,
						width: 10,
					},
					{
						header: `Custom ID`,
						key: 'borrower',
						width: 40
					},
					{
						header: `VaR`,
						key: 'var',
						width: 18,
					},
					{
						header: `BD ID`,
						key: 'bdId',
						width: 18,
					},
					{
						header: `SWAP ID`,
						key: 'swapId',
						width: 20,
					},
				]
				xsaSheet.columns = xsaColumns
				xsaSheet.addRows(xsaTrades)
				xsaSheet.views = [{
					state: 'frozen',
					ySplit: 1,
				}]

				const ssbColumns = [
					{
						header: `Associated Bank`,
						key: 'customer',
						width: 40,
					},
					{
						header: `CME/LCH`,
						key: `clearingHouse`,
						width: 10,
					},
					{
						header: `Custom ID`,
						key: 'borrower',
						width: 40
					},
					{
						header: `SWAP ID`,
						key: 'swapId',
						width: 20,
					},
				]
				ssbSheet.columns = ssbColumns
				ssbSheet.addRows(ssbTrades)
				ssbSheet.views = [{
					state: 'frozen',
					ySplit: 1,
				}]

				const fmvColumns = [
					{
						header: `Custom ID`,
						key: 'borrower',
						width: 40
					},
					{
						header: `SWAP ID`,
						key: 'swapId',
						width: 20,
					},
				]
				fmvSheet.columns = fmvColumns
				fmvSheet.addRows(fmvTrades)
				fmvSheet.views = [{
					state: 'frozen',
					ySplit: 1,
				}]

				const acbColumns = [
					{
						header: `Associated Bank`,
						key: 'customer',
						width: 40,
					},
					{
						header: `CME/LCH`,
						key: `clearingHouse`,
						width: 10,
					},
					{
						header: `Custom ID`,
						key: 'borrower',
						width: 40
					},
					{
						header: `VaR`,
						key: 'var',
						width: 18,
					},
					{
						header: `BD ID`,
						key: 'bdId',
						width: 18,
					},
					{
						header: `SWAP ID`,
						key: 'swapId',
						width: 20,
					},
				]
				acbSheet.columns = acbColumns
				acbSheet.addRows(acbTrades)
				acbSheet.views = [{
					state: 'frozen',
					ySplit: 1,
				}]

				const data = await workbook.xlsx.writeBuffer()
				forceDownload(data, `${filename}.xlsx`, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })


			} catch (error) {
				this.$notice(`ERROR: ${error}`)
				this.isLoading = false
			} finally {
				this.isLoading = false
			}
		},
		hasB(trade) {
			if (trade.swCounterParty.match(/(B)$/)) return false
		},
		isB(trade) {
			if (trade.swCounterParty.match(/(B)$/)) return true
			return false
		},
		async generateReport(reportType) {
			this.isLoading = true
			let results
			try {
				results = await this.$store.dispatch(`tools/generateBvalReport`, reportType)
			} catch (error) {
				this.$notice(`ERROR: ${error}`)
			} finally {
				this.isLoading = false
			}

			switch (reportType) {
			}
		},
		toggleRow(key) {
			let idx = this.isToggled.findIndex(a => a == key)
			if (idx > -1) {
				this.isToggled.splice(idx, 1)
			} else {
				this.isToggled.push(key)
			}
		},
		changeLimit(val) {
			let query = {
				...this.$route.query,
				...{
					limit: val,
				}
			}

			this.$router.push({ query })
		},
		submitForm() {
			const fields = []
			const values = []

			for (const k in this.search) {
				fields.push(k)
				values.push(this.search[k])
			}
			let query = {
				...this.$route.query,
				...{
					fields,
					values,
				}
			}

			this.$router.push({ query, })
			this.isToggled = []
		},
		clearQuery() {
			this.search = {}
			this.$router.push({ query: {fields: null, values: null}})
		},
		hideModal(clicked) {
			if (clicked) {
				this.$router.replace({ name: 'bval', params: { tab: this.$route.params.tab }})
			} else {
				this.$router.back()
			}
		},
	},
	metaInfo: () => ({
		title: `BVAL All Positions`,
	}),
	watch: {
		$route: {
			immediate: true,
			handler: async function (to, from) {
				// Prevent reloads
				if (to.name == 'tradeEdit') return
				if (from && JSON.stringify(to.query || {}) == JSON.stringify(from.query || {}) ) return

				this.isLoading = true
				const { p, limit, fields, values, orderBy, order, } = to.query

				if (this.isLoading) {
					this.$store.dispatch(`tools/cancelRequest`)
					setTimeout(() => {
						this.isLoading = true
					}, 250)
				}

				try {
					await this.$store.dispatch(`tools/queryBvalData`, { p, limit, fields, values, orderBy, order, })
				} catch (error) {
					this.$notice(`ERROR: ${error}`)
				} finally {
					this.isLoading = false
				}

			},
		},
		valuationDate (newVal, oldVal) {
			if (oldVal && newVal !== this.search.valuationDate) {
				this.search.valuationDate = newVal
				this.submitForm()
			} else {
				this.search.valuationDate = newVal
			}
		},
	},
}
</script>

<style scoped lang="scss">

.mdc-data-table {
	$table: &;

	&__row {
		cursor: pointer;

		&--selected {
			background-color: modules.color_('background', 'xlight');
			border-bottom-width: 0;

			#{$table}__cell {
				font-weight: bold;
			}

			& + #{$table}__row--details {
				background-color: modules.color_('background', 'xlight');
				border-top-color: modules.color_('background', 'xlight');

				&:hover {
					background-color: modules.color_('background', 'xlight');
				}

				td {
					display: table-cell;
				}
			}
		}

		&--details {
			&:hover {
				background-color: transparent;
			}

			td {
				display: none;
				height: 10px;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		&.row--is-b {
			border-top-color: rgba(0, 0, 0, 0.10);
			border-top-style: dashed;
		}
	}

	&__cell {
		// @include modules.fontSize(13px);

		#{$table}__row--selected & {
			font-weight: 500;
		}

		&--numeric {
			justify-content: flex-end;

			#{$table}__row--selected & {
				font-weight: bold;
			}
		}

		&--details {
			@include modules.fontSize(12px);
			color: #212B36;
			cursor: auto;
		}

		&--name {
			line-height: 1.1;
			overflow: hidden;
		}
	}

	&__edit {
		align-items: center;
		display: flex;
		grid-column: 1/ -1;
		justify-content: flex-end;
	}

	&__pagination {
		@include modules.gutter('padding');
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		::v-deep .pagination {
			@include modules.gutter('padding', 0);
		}
	}

	.bval-trade {
		&__details {
			display: grid;
			grid-template-columns: repeat(4, 1.65fr 1.55fr);
			line-height: 1.2;
			padding: 1rem;
			padding-top: 0;
			row-gap: 1rem;

			dt {
				border-bottom: 1px dotted modules.color_('border','light');
				margin:0;
				padding-bottom: 0.25rem;
				&:not(:empty) {
					&:after {
						content: ':';
					}
				}
			}

			dd {
				border-bottom: 1px dotted modules.color_('border','light');
				font-weight: 500;
				margin:0;
				margin-right: 1.5rem;
				text-align: right;
			}
		}
	}
}

.form-section {
	&--search {
		@include modules.gutter('padding-top', .5);
		@include modules.gutter('padding-bottom', .5);
	}

	&--bval-controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}


.search {
	max-height: 0;
	overflow: hidden;
	transition: all 250ms modules.$ease-standard;

	&--open {
		max-height: 700px;
		overflow: visible;
		padding-top: 20px;
		padding-bottom: 10px;
	}
}

.form-tools {
	&__totals {
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		display: grid;
		justify-items: center;
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 2rem;
		width: 100%;

		.form-tools__meta {
			grid-column-start: 4;

			dt {
				text-align: right;
			}
		}

		transition: color 250ms linear, text-shadow 250ms linear;
		text-shadow: 0 0 1.5px rgba(0, 0, 0, 0);

		&--loading {
			color: transparent;
			text-shadow: 0 0 1.5px rgba(0, 0, 0, 0.5);
		}
	}

	&__total {
		text-align: center;
		dt,
		dd {
			margin: 0;
			padding: 0;
		}

		dt {
			@include modules.fontSize(13px);
			font-weight: bold;
			text-transform: uppercase;
		}

		dd {
			@include modules.fontSize(23px);
			line-height: 1.4;
		}


	}

	&__details {
		@include modules.gutter('grid-column-gap');
		display: grid;
		grid-template-columns: auto auto auto;
		justify-content: flex-end;
	}

	&__meta {
		@include modules.fontSize(12px);
		display: flex;
		text-align: center;
		dt,
		dd {
			margin: 0;
			padding: 0 0.15rem;
		}

		dt:after {
			content: ':';
		}


	}

	&__tabs {
		border-top: 1px solid rgba(0, 0, 0, 0.06);
	}
}
</style>
